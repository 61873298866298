<template>
    <div>

        <div id="container_3D" @click="onDocumentMouseDown" v-if="parkVisible">
            <div id="layer" v-if="!(speed == 100)">
                <div class="progressStrip">
                    <div class="progressImg">
                        <div :style="'width:' + speed + '%'">
                            <img src="@/assets/lou.png" alt="">
                        </div>
                    </div>
                    <div class="progressBar">
            <span class="white" :style="'width:' + speed + '%'">
            </span>
                    </div>
                    <div class="tips">Loading...{{speed + '%'}}</div>
                </div>
            </div>

            <div v-if="isBian" style="position: fixed">
                <div class="bianBlack isBian1">

                </div>
                <div class="bianBlack isBian2">

                </div>
                <div class="bianBlack isBian3">

                </div>
                <div class="bianBlack isBian4">

                </div>
            </div>

            <div class="switch">
                <img class="panorama" src="@/assets/niaokan.png" alt="" v-if="state != 1" @click.stop="panorama">
                <img class="roam" src="@/assets/manyou.png" alt="" v-else @click.stop="roam">
            </div>
            <div class="zoom" @click="fullScreenView">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menuziyuanldpi"></use>
                </svg>
            </div>
            <CoolDialog
                    dialog-title="df404-三号楼大门"
                    dialog-width="1200px"
                    frame-width="390px"
                    :dialog-visible="monitorVisible"
                    :scale="scale"
                    @onClose="controlDirection"
            >
                <template v-slot:dialog-content>
                    <div style="width: 100%; padding: 30px;">
                        <div style="position: relative;">
                            <video id="camera_7" src="" style="width: 100%; height: 100%;"></video>
                            <div class="row_img">
                                <div class="row_img_item" v-for="item in cameraCaptureList" :key="item.createTime">
                                    <img :src="snapImgHost + item.phoneUrl" alt="">
                                    <div :class="{'row_img_item_type' : true , 'green' : item.type == 8 }">
                                        {{abnormalType[item.type]}}
                                    </div>
                                </div>
                            </div>
                            <div class="col_img">
                                <img class="col_img_item" v-for="item in captureNewFacePhotoList" :key="item.id"
                                     :src="item.faceUrl" alt="">
                            </div>
                        </div>
                    </div>
                </template>
            </CoolDialog>
            <CoolDialog
                    dialog-width="440px"
                    :dialog-left="dialogLeft"
                    dialog-title="3号楼-楼栋概况"
                    frame-width="307px"
                    header-title-color="#fff"
                    :dialog-visible="stateRoomVisible"
                    :scale="scale"
                    transformOrigin="left"
                    translate="translateY(-50%)"
                    @onClose="controlDirection"
            >
                <template v-slot:dialog-content>
                    <div class="dialog-container">
                        <div class="survey">
                            <div class="enterprise">
                                <!--                          <p class="num">{{lesseeCount}}家</p>-->
                                <!--                          展示用-->
                                <p class="num">23家</p>

                                <p class="text">入驻企业</p>
                            </div>
                            <div class="line"></div>
                            <div class="user">
                                <!--                          <p class="num">{{userCount}}人</p>-->
                                <!--                          展示用-->
                                <p class="num">356人</p>

                                <p class="text">楼内用户</p>
                            </div>
                        </div>
                        <div class="sex">
                            <div class="sex_title">
                                <img src="@/assets/smartPark/frame.png" alt="">
                                <div class="text">楼内用户性别占比</div>
                            </div>
                            <div class="man">
                                <div class="list">
                                    <svg class="icon" v-for="index in 10" :key="index" style="font-size: 24px;">
                                        <!--                                  <use v-if="index <= (genderRate.manRate * 10)" xlink:href="#icon-menuyellowMan-copy"></use>-->
                                        <!--                                  展示用的假数据-->
                                        <use v-if="index <= 5" xlink:href="#icon-menuyellowMan-copy"></use>

                                        <use v-else xlink:href="#icon-menuwhiteMan-copy"></use>
                                    </svg>
                                </div>
                                <div class="proportion">
                                    <!--                              <p class="percentage">{{ genderRate.manRate * 100 }}%</p>-->
                                    <!--                              展示用-->
                                    <p class="percentage">52%</p>

                                    <p class="text">男性用户</p>
                                </div>
                            </div>
                            <div class="woman">
                                <div class="list">
                                    <svg class="icon" v-for="index in 10" :key="index" style="font-size: 24px;">
                                        <!--                                  <use v-if="index <= (genderRate.femaleRate * 10)" xlink:href="#icon-menunv"></use>-->
                                        <!--                                  展示用-->
                                        <use v-if="index <= 5" xlink:href="#icon-menunv"></use>

                                        <use v-else xlink:href="#icon-menuwhiteWoman-copy"></use>
                                    </svg>
                                </div>
                                <div class="proportion">
                                    <!--                              <p class="percentage">{{ genderRate.femaleRate * 100 }}%</p>-->
                                    <!--                              展示用-->
                                    <p class="percentage">48%</p>

                                    <p class="text">女性用户</p>
                                </div>
                            </div>
                        </div>
                        <div class="ageDistribution">
                            <div class="sex_title">
                                <img src="@/assets/smartPark/frame.png" alt="">
                                <div class="text">楼内用户年龄分布</div>
                            </div>
                            <div class="chart">
                                <!--                          <div class="chart-item" v-for="(item, index) in ageInterList" :key="index">-->
                                <!--                              <div class="chart-item-info">-->
                                <!--                                  <span class="square"></span>-->
                                <!--                                  <span>{{ageRange[item.ageRange]}}</span>-->
                                <!--                                  <span>{{item.ageCount || 0}}人</span>-->
                                <!--                              </div>-->
                                <!--                              <div class="line">-->
                                <!--                                  <div class="line-bg">-->
                                <!--                                      <div class="line-color" :style="'width:' + item.countRate * ageMagnification + '%'"></div>-->
                                <!--                                      <div class="num">{{item.countRate || 0}}%</div>-->
                                <!--                                  </div>-->
                                <!--                              </div>-->
                                <!--                          </div>-->

                                <!--                          假数据-->
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[5]}}</span>
                                        <span>33人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 35%"></div>
                                            <div class="num">9%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[4]}}</span>
                                        <span>42人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 47%"></div>
                                            <div class="num">12%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[3]}}</span>
                                        <span>67人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 76%"></div>
                                            <div class="num">19%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[2]}}</span>
                                        <span>85人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 100%"></div>
                                            <div class="num">24%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[1]}}</span>
                                        <span>43人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 47%"></div>
                                            <div class="num">12%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chart-item">
                                    <div class="chart-item-info">
                                        <span class="square"></span>
                                        <span>{{ageRange[0]}}</span>
                                        <span>86人</span>
                                    </div>
                                    <div class="line">
                                        <div class="line-bg">
                                            <div class="line-color" style="width: 100%"></div>
                                            <div class="num">24%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </CoolDialog>
            <CoolDialog
                    dialog-width="440px"
                    dialog-title="租赁情况"
                    dialog-left="none"
                    :dialog-right="dialogRight"
                    frame-width="190px"
                    header-title-color="#fff"
                    :scale="scale"
                    transformOrigin="right"
                    translate="translateY(-50%)"
                    :dialog-visible="stateRoomVisible"
                    @onClose="controlDirection"
            >
                <template v-slot:dialog-content>
                    <div class="dialog-container">
                        <div class="rentalRate">
                            <div class="lease-situation-chart">
                                <div class="info">
                                    <div class="room">
                                        <!--                                  <p class="num">{{regionRoomCount}}个</p>-->
                                        <!--                                  假数据-->
                                        <p class="num">192个</p>
                                        <p class="text">总房间数</p>
                                    </div>
                                    <div class="area">
                                        <p class="num">11752m²</p>
                                        <p class="text">总面积</p>
                                    </div>
                                </div>
                                <div id="tenant_3D"></div>
                            </div>
                        </div>
                        <div class="floorOccupancyRate">
                            <div class="title">
                                <img style="width: 230px;" src="@/assets/smartPark/frame.png" alt="">
                                <div class="text">楼层出租率</div>
                            </div>
                            <!--                      <div class="floorList">-->
                            <!--                          <div class="chart">-->
                            <!--                              <div class="chart-item" v-for="(item, index) in rentalRate" :key="index">-->
                            <!--                                  <div class="chart-item-info">-->
                            <!--                                      <span class="square"></span>-->
                            <!--                                      <span>{{item.buildingName}}层</span>-->
                            <!--                                      <span>{{item.buildingRoomCount}}间</span>-->
                            <!--                                      <span>{{item.buildingRoomSpaceSum}}m²</span>-->
                            <!--                                  </div>-->
                            <!--                                  <div class="line">-->
                            <!--                                      <div class="line-bg">-->
                            <!--                                          <div class="line-color" :style="'width:' + item.buildingRoomOccRate + '%'"></div>-->
                            <!--                                      </div>-->
                            <!--                                      <div class="num">{{item.buildingRoomOccRate}}%</div>-->
                            <!--                                  </div>-->
                            <!--                              </div>-->
                            <!--                          </div>-->
                            <!--                      </div>-->
                            <!--                      展示用假数据-->
                            <div class="floorList">
                                <div class="chart">
                                    <div class="chart-item">
                                        <div class="chart-item-info">
                                            <span class="square"></span>
                                            <span>2层</span>
                                            <span>21间</span>
                                            <span>1246m²</span>
                                        </div>
                                        <div class="line">
                                            <div class="line-bg">
                                                <div class="line-color" style="width: 26%"></div>
                                            </div>
                                            <div class="num">26%</div>
                                        </div>
                                    </div>
                                    <div class="chart-item">
                                        <div class="chart-item-info">
                                            <span class="square"></span>
                                            <span>3层</span>
                                            <span>19间</span>
                                            <span>1026m²</span>
                                        </div>
                                        <div class="line">
                                            <div class="line-bg">
                                                <div class="line-color" style="width: 21%"></div>
                                            </div>
                                            <div class="num">21%</div>
                                        </div>
                                    </div>
                                    <div class="chart-item">
                                        <div class="chart-item-info">
                                            <span class="square"></span>
                                            <span>5层</span>
                                            <span>27间</span>
                                            <span>1540m²</span>
                                        </div>
                                        <div class="line">
                                            <div class="line-bg">
                                                <div class="line-color" style="width: 18%"></div>
                                            </div>
                                            <div class="num">18%</div>
                                        </div>
                                    </div>
                                    <div class="chart-item">
                                        <div class="chart-item-info">
                                            <span class="square"></span>
                                            <span>6层</span>
                                            <span>31间</span>
                                            <span>1721m²</span>
                                        </div>
                                        <div class="line">
                                            <div class="line-bg">
                                                <div class="line-color" style="width: 14%"></div>
                                            </div>
                                            <div class="num">14%</div>
                                        </div>
                                    </div>
                                    <div class="chart-item">
                                        <div class="chart-item-info">
                                            <span class="square"></span>
                                            <span>7层</span>
                                            <span>28间</span>
                                            <span>1469m²</span>
                                        </div>
                                        <div class="line">
                                            <div class="line-bg">
                                                <div class="line-color" style="width: 13%"></div>
                                            </div>
                                            <div class="num">13%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </CoolDialog>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import * as Three from 'three';
    import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
    import OrbitControls from 'three-orbitcontrols';
    import {Capsule} from 'three/examples/jsm/math/Capsule.js';
    import {Octree} from 'three/examples/jsm/math/Octree.js';
    import CoolDialog from "@/views/SmartPark/CoolDialog";

    import {STORAGE_KEY} from "@/constant";

    import {
        snapImgHost,
        openRealTimeStreamUrl,
        queryCameraCaptureAbnormalInfoUrl,
        queryBuildingOccRateUrl,
        queryBuildingCodeByNameUrl,
        queryLesseeByBuildingUrl,
        queryUserByBuildingUrl, stateRoomUrl,
        queryCaptureNewFacePhotoUrl,
    } from "@/requestUrl";
    import {initStreamVideoPlayer} from "@/utils";

    let charts = [];
    export default {
        name: "SmartPark",
        data() {
            return {
                dialogLeftTimer: null,
                groundTimer: null,
                isZi: false,
                ziA: null,
                ziB: null,
                timerOutW: null,
                isRouter: false,//判断是否旋转
                routers: null,
                isBian: false,
                snapImgHost,
                parkVisible: true,
                camera: null,
                scene: null,
                renderer: null,
                mesh: null,
                loader: null,
                clock: null,
                worldOctree: null,
                playerCollider: null,
                // 加载模型进度
                speed: 0,
                // 1:是鸟瞰，2:是漫游
                state: 1,
                // 监控弹窗显隐
                monitorVisible: false,
                // 速度
                playerVelocity: null,
                // 方向
                playerDirection: null,
                keyStates: {},
                // 切换有鼠标和无鼠标的模式
                pattern: false,
                // 不同管理区加载不同模型
                modelUrl: {
                    // "410103000000000002": '/common/models/xinlouyu.glb',
                    "410103000000000002": 'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/xinlouyu.glb',
                },
                // 租聘信息
                stateRoomInfo: {},
                // 租控图显隐
                stateRoomVisible: false,
                // 天空图
                urls: [
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/px.jpg',
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/nx.jpg',
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/py.jpg',
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/ny.jpg',
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/pz.jpg',
                    'https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/nz.jpg'
                ],
                // 防抖状态
                antiShake: false,
                // 异常列表
                cameraCaptureList: [],
                // 最新照片
                captureNewFacePhotoList: [],
                // 异常状态
                abnormalType: {
                    1: "人员聚集",
                    2: "为戴口罩",
                    3: "吸烟",
                    4: "跌倒",
                    5: "相机被遮挡",
                    6: "区域入侵",
                    7: "车辆违停",
                    8: "VIP",
                    9: "可疑人",
                },
                regionCode: this.$vc.getCurrentRegion().code,
                // 楼内用户人数
                userCount: null,
                // 园区企业数量
                lesseeCount: null,
                // 楼内用户性别比例
                genderRate: {},
                // 楼内用户年龄分布长度最大倍率
                ageMagnification: null,
                // 楼内用户年龄分布
                ageInterList: [],
                // 年龄区间
                ageRange: {
                    0: '未知',
                    1: '16-20岁',
                    2: '21-30岁',
                    3: '31-40岁',
                    4: '41-50岁',
                    5: '50岁以上',
                },
                // 总房间数
                sumArea: '',
                regionRoomCount: '',
                rentalRate: [],
                // 楼栋id
                buildingCode: null,
                scale: '',
                //场景渲染ID
                rafId: "",
                //地面
                groundG: null,
                //弹框
                dialogLeft: '20px',
                dialogRight: '20px'
            }
        },
        components: {
            CoolDialog,
        },
        beforeDestroy() {
            charts.forEach(chart => chart.dispose());
            charts = [];
        },
        mounted() {
            this.init();
            this.animate();
            this.monitorRouter();
            this.judgeZoom();
            let timebar = setTimeout(() => {
                this.fullScreenView();
                clearTimeout(timebar);
            });
            this.queryCameraCapture();


            //调整
            this.setScale();
        },
        methods: {
            init() {
                // 创建场景
                this.scene = new Three.Scene();
                // gltf/glb格式模型
                this.loader = new GLTFLoader();

                this.clock = new Three.Clock();

                this.playerVelocity = new Three.Vector3();
                this.playerDirection = new Three.Vector3();
                this.playerCollider = new Capsule(new Three.Vector3(0, 0.35, -100), new Three.Vector3(0, 2, -100), 0.5);
                this.worldOctree = new Octree();
                // 加载模型
                if (!this.modelUrl[this.$vc.getCurrentRegion().code]) {
                    this.parkVisible = false;
                    return;
                }
                this.loader.load(this.modelUrl[this.$vc.getCurrentRegion().code], (gltf) => {
                        this.scene.add(gltf.scene);
                        gltf.scene.traverse(child => {
                            if (child.name == 'Plane001001') {
                                this.groundG = child;
                            }

                        });
                    }, (pro) => {
                        if (pro.loaded / pro.total == 1) {
                            this.speed = 99.99;
                            setTimeout(() => {
                                this.speed = 100;
                            }, 2000)
                            return;
                        }
                        this.speed = (pro.loaded / pro.total * 100).toFixed(2);

                    },
                    () => {
                    });

                // 添加环境光
                const ambient = new Three.AmbientLight(0xffffff,);
                this.scene.add(ambient);

                // 设置天空盒
                this.scene.background = new Three.CubeTextureLoader().load(this.urls, () => {
                }, () => {
                }, () => {
                });

                // 添加摄像机
                this.camera = new Three.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000000);
                // 设置位置
                this.camera.position.set(100, 40, -100);
                // 设置角度
                // 千万不要动！不然漫游y方向就错了！⬇
                this.camera.rotation.order = 'YXZ';

                // 创建控件（用户鸟瞰时旋转、缩放、移动）
                this.controls = new OrbitControls(this.camera, document.getElementById('container_3D'));// 创建控件对象
                // this.controls.maxPolarAngle = 1.3;
                // this.controls.minDistance = 100;
                // this.controls.maxDistance = 200;

                // 创建渲染实例
                this.renderer = new Three.WebGLRenderer();
                this.renderer.setSize(window.innerWidth, window.innerHeight);

                // 插入canvas对象
                document.getElementById('container_3D').appendChild(this.renderer.domElement);
                document.getElementById('container_3D').addEventListener('keydown', (event) => {
                    this.keyStates[event.code] = true;
                })
                document.getElementById('container_3D').addEventListener('keyup', (event) => {
                    this.keyStates[event.code] = false;
                })
            },
            // 鼠标控制方向
            setRotation(event) {
                this.camera.rotation.y -= event.movementX / 500;
                this.camera.rotation.x -= event.movementY / 500;
            },
            // 漫游
            roam() {
                //录完视频后移除
                this.scene.rotation.x = 0;
                this.scene.rotation.y = 0;
                this.scene.rotation.z = 0;

                this.state = 2;
                this.playerCollider = new Capsule(new Three.Vector3(12, 0.35, -90), new Three.Vector3(12, 2, -90), 0.5);
                this.camera.rotation.y = 3;
                this.camera.rotation.x = 0.1;
                this.controls.enabled = false;
                document.getElementById('container_3D').addEventListener('mousemove', this.setRotation);
                this.camera.aspect = document.getElementById('container_3D').innerWidth / document.getElementById('container_3D').innerHeight;
                document.getElementById('container_3D').addEventListener('mousedown', this.mouseClick);
                this.mouseClick();
            },
            // 鸟瞰
            panorama() {
                this.state = 1;
                this.camera.position.set(100, 40, -100);
                this.camera.rotation.x = -0.2756427992162654;
                this.camera.rotation.y = 2.356194490192345;
                this.controls.enabled = true;
                document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                document.getElementById('container_3D').removeEventListener('mousedown', this.mouseClick);
            },
            //做完视频后删除
            //按键自动旋转
            rotateBtn() {
                this.scene.rotation.y -= 0.004;
                this.routers = requestAnimationFrame(this.rotateBtn);
            },
            // 获取鼠标点击的位置
            onDocumentMouseDown(event) {
                if (this.monitorVisible || this.stateRoomVisible) {
                    return;
                }
                event.preventDefault();
                let vector = new Three.Vector3();//三维坐标对象
                vector.set(((event.clientX - document.getElementById('container_3D').offsetLeft) / document.getElementById('container_3D').clientWidth) * 2 - 1, -((event.clientY - document.getElementById('container_3D').offsetTop) / document.getElementById('container_3D').clientHeight) * 2 + 1, 0.5);
                vector.unproject(this.camera);
                let raycaster = new Three.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize());
                let intersects = raycaster.intersectObjects(this.scene.children, true);
                if (intersects.length > 0) {
                    let selected = intersects[0];//取第一个物体
                    // 获取第一个物体名字
                    let objName = selected.object.name;
                    if (this.state === 2) {
                        //  TODO: 录完视频后删除
                        // this.initBuildingInfo();
                        // this.stateRoomVisible = true;
                        console.log(objName);
                        // 用名字判断点击时需要弹出的弹窗, 并去除漫游控制方向
                        switch (objName) {
                            case 'shexiangji':
                                this.monitorVisible = true;
                                document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                                // this.queryAbnormal();
                                this.changeCameraVideoPlayStatus();
                                this.queryCameraCapture();
                                this.queryCaptureNewFacePhoto();
                                break;
                            case 'shexiangji2':
                            case 'shexiangji3':
                                this.monitorVisible = true;
                                document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                                this.changeCameraVideoPlayStatus();
                                this.queryCameraCapture();
                                this.queryCaptureNewFacePhoto();
                                break
                            case 'damen':

                                if (document.exitFullScreen) {
                                    document.exitFullScreen();
                                } else if (document.mozCancelFullScreen) {
                                    document.mozCancelFullScreen();
                                } else if (document.webkitExitFullscreen) {
                                    document.webkitExitFullscreen();
                                } else if (element.msExitFullscreen) {
                                    element.msExitFullscreen();
                                }
                                //清空场景
                                this.clearScene();
                                this.$router.push({path: 'RoamingIndoor'});
                                break;
                            case 'damen2':
                            case 'damen3':
                                if (document.exitFullScreen) {
                                    document.exitFullScreen();
                                } else if (document.mozCancelFullScreen) {
                                    document.mozCancelFullScreen();
                                } else if (document.webkitExitFullscreen) {
                                    document.webkitExitFullscreen();
                                } else if (element.msExitFullscreen) {
                                    element.msExitFullscreen();
                                }
                                cancelAnimationFrame(this.rafId)
                                console.log(objName);
                                if (objName == 'damen2') {
                                    window.localStorage.setItem('damenJ', 'damen2J');
                                } else {
                                    window.localStorage.setItem('damenJ', 'damen3J');
                                }
                                this.$router.push({path: 'insideBuilding'});
                                break;
                            case '3haolou_8':
                            case '3haolou_6':
                            case '3haolou_2':
                            case '3haolou_3':
                            case '3haolou_5':
                                this.getBuilding('3haolou');
                                this.stateRoomVisible = true;
                                document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                                break;
                            case '网格287_1':
                                this.getBuilding('2haolou');
                                this.stateRoomVisible = true;
                                document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                                break;
                            // case 'shexiangji2':
                            // case 'shexiangji3':
                            //     this.getBuilding('2haolou');
                            //     this.stateRoomVisible = true;
                            //     document.getElementById('container_3D').removeEventListener('mousemove', this.setRotation);
                            //     // this.queryAbnormal();
                            //     this.changeCameraVideoPlayStatus();
                            //     this.queryCameraCapture();
                            //     this.queryCaptureNewFacePhoto();
                            //     break;
                            default:
                                break;
                        }
                    }
                }
            },
            //释放内存
            clearScene() {
                cancelAnimationFrame(this.rafId)
                this.scene.traverse((child) => {
                    if (child.material) {
                        child.material.dispose();
                    }
                    if (child.geometry) {
                        child.geometry.dispose();
                    }
                    child = null;
                });

                // 场景中的参数释放清理或者置空等
                // this.sceneDomElement.innerHTML = '';
                // this.renderer.forceContextLoss();
                // this.renderer.dispose();
                this.scene.clear();
                // this.flows = [];
                 this.scene = null;
                // this.camera = null;
                // this.controls = null;
                // this.renderer.domElement = null;
                // this.renderer = null;
                // this.sceneDomElement = null;
                // console.log('clearScene');
            },
            getForwardVector() {
                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();
                return this.playerDirection;
            },
            getSideVector() {
                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();
                this.playerDirection.cross(this.camera.up);
                return this.playerDirection;
            },
            // 触发按键事件
            moveControls(deltaTime) {
                console.log('监听园区A');
                // 设置移动的速度
                const speed = 100;
                if (this.keyStates['KeyW']) {
                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(speed * deltaTime));
                }
                if (this.keyStates['KeyS']) {
                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(-speed * deltaTime));
                }
                if (this.keyStates['KeyA']) {
                    this.playerVelocity.add(this.getSideVector().multiplyScalar(-speed * deltaTime));
                }
                if (this.keyStates['KeyD']) {
                    this.playerVelocity.add(this.getSideVector().multiplyScalar(speed * deltaTime));
                }
                if (this.keyStates['Tab']) {
                    document.getElementById('container_3D').addEventListener('mousedown', this.mouseClick);
                }
                if (this.keyStates['Digit1']) {
                    this.playerCollider = new Capsule(new Three.Vector3(-30, 6, 45), new Three.Vector3(-30, 7, 45), 0.5);
                    this.camera.rotation.y = 7.8;
                }
                if (this.keyStates['Digit2']) {
                    this.playerCollider = new Capsule(new Three.Vector3(27, 33, -56), new Three.Vector3(27, 34, -56), 0.5);
                    this.camera.rotation.y = 9.4;
                }
                if (this.keyStates['Digit3']) {
                    this.playerCollider = new Capsule(new Three.Vector3(12, 0.35, -90), new Three.Vector3(12, 2, -90), 0.5)
                    this.camera.rotation.y = 3;
                    this.camera.rotation.x = 0.1;
                }
                if (this.keyStates['Digit4']) {
                    this.playerCollider = new Capsule(new Three.Vector3(100, 38.65, -100), new Three.Vector3(100, 40, -100), 0.5)
                    this.panorama();
                    document.exitPointerLock();
                }
                //做完视频移除
                if (this.keyStates['KeyN']) {
                    clearTimeout(this.sss);
                    this.sss = setTimeout(() => {
                        if (!this.stateRoomVisible) {
                            this.initBuildingInfo();
                            this.stateRoomVisible = true;
                        } else {
                            this.controlDirection();
                            this.stateRoomVisible = false;
                        }

                    }, 20)
                }


            },
            // 将鼠标指针锁定在指定元素上
            mouseClick() {
                document.getElementById('container_3D').requestPointerLock();
                if (this.pattern) {
                    document.getElementById('container_3D').removeEventListener('mousedown', this.mouseClick);
                    document.exitPointerLock();
                    this.pattern = false;
                } else {
                    this.pattern = true;
                }
            },
            // 碰撞检测 (目前移除，谨慎加，浏览器容易崩)
            playerCollitions() {
                const result = this.worldOctree.capsuleIntersect(this.playerCollider);
                if (result) {
                    this.playerVelocity.addScaledVector(result.normal, -result.normal.dot(this.playerVelocity));
                    this.playerCollider.translate(result.normal.multiplyScalar(result.depth));
                }
            },
            updatePlayer(deltaTime) {
                const damping = Math.exp(-3 * deltaTime) - 1;
                this.playerVelocity.addScaledVector(this.playerVelocity, damping);
                const deltaPosition = this.playerVelocity.clone().multiplyScalar(deltaTime);
                this.playerCollider.translate(deltaPosition);
                this.playerCollitions();
                this.camera.position.copy(this.playerCollider.end);
            },

            // 弹窗关闭, 添加漫游鼠标控制方向
            controlDirection() {
                this.monitorVisible = false;
                this.stateRoomVisible = false;
                document.getElementById('container_3D').addEventListener('mousemove', this.setRotation);
            },

            // 设置全屏
            fullScreenView() {
                const container_3D = document.getElementById('container_3D');
                if (container_3D.requestFullscreen) {
                    container_3D.requestFullscreen();
                } else if (container_3D.mozRequestFullScreen) {
                    container_3D.mozRequestFullScreen();
                } else if (container_3D.webkitRequestFullscreen) {
                    container_3D.webkitRequestFullscreen();
                } else if (container_3D.msRequestFullscreen) {
                    container_3D.msRequestFullscreen();
                }
            },
            // 判断全屏
            judgeZoom() {
                document.addEventListener('fullscreenchange', function (e) {
                    if (document.fullscreenElement) {
                        document.getElementsByClassName("zoom")[0].style.opacity = 0;
                    } else {
                        document.getElementsByClassName("zoom")[0].style.opacity = 1;
                    }
                });
            },
            //做完视频后删除
            xuanCamer() {
                this.ziB = requestAnimationFrame(this.xuanCamer);
                this.camera.rotation.y -= 0.004;

            },
            //  切换背景
            switchBackground() {
                //做完视频删除
                if (this.keyStates['KeyX']) {

                    clearTimeout(this.ziA);
                    this.ziA = setTimeout(() => {
                        if (!this.isZi) {
                            this.isZi = true;
                            this.xuanCamer();

                        } else {
                            cancelAnimationFrame(this.ziB);
                            this.isZi = false;
                        }
                    }, 20);
                }
                if (this.keyStates['KeyM']) {
                    clearTimeout(this.timerOutW);
                    this.timerOutW = setTimeout(() => {
                        if (!this.isRouter) {
                            this.rotateBtn();
                            this.isRouter = true;
                        } else {
                            cancelAnimationFrame(this.routers);
                            this.isRouter = false;
                        }
                    }, 20)
                }
                if (this.keyStates['KeyB']) {
                    this.roam();
                }

                //隐藏园区路面
                if (this.keyStates['Digit8']) {
                    clearTimeout(this.groundTimer);
                    this.groundTimer = setTimeout(() => {
                        if (this.groundG.material.visible) {
                            this.groundG.material.visible = false
                        } else {
                            this.groundG.material.visible = true
                        }
                    }, 200)

                }
                //控制弹框居中
                if (this.keyStates['Digit9']) {
                    clearTimeout(this.dialogLeftTimer);
                    this.dialogLeftTimer = setTimeout(() => {
                        if (this.dialogRight == '20px') {
                            this.dialogRight = '350px'
                            this.dialogLeft = '350px'
                        } else {
                            this.dialogRight = '20px'
                            this.dialogLeft = '20px'
                        }
                    }, 200)

                }
                if (this.keyStates['Digit5']) {
                    if (this.antiShake) {
                        return;
                    }
                    this.antiShake = true;
                    if (this.scene.background.image.length > 0) {
                        this.isBian = true;
                        this.scene.background = new Three.CubeTextureLoader().load([], () => {
                        }, () => {
                        }, () => {
                        });
                    } else {
                        this.scene.background = new Three.CubeTextureLoader().load(this.urls, () => {
                        }, () => {
                        }, () => {
                        });
                        this.isBian = false;
                    }
                    let timer = setTimeout(() => {
                        this.antiShake = false;
                        clearTimeout(timer);
                    }, 300)
                    timer
                }
                // TODO: 做完视频后移除代码
                if (this.keyStates['Digit6']) {
                    console.log(this.camera)
                    this.camera.position.y -= 1;
                }
                if (this.keyStates['Digit7']) {
                    console.log(this.camera)
                    this.camera.position.y += 1;
                }
            },
            animate() {
                if (this.state === 2) {
                    const deltaTime = Math.min(0.05, this.clock.getDelta()) / 5;
                    this.moveControls(deltaTime);
                    this.updatePlayer(deltaTime);
                }
                this.switchBackground();
                cancelAnimationFrame(this.rafId);
                this.rafId = requestAnimationFrame(this.animate);
                //console.log('主场景渲染中');
                this.renderer.render(this.scene, this.camera);
                //this.setScale();
            },
            // 调整缩放倍数
            setScale() {
                console.log('调整缩放');
                let clWidth = document.body.clientWidth;
                if (clWidth < 1366) {
                    this.scale = 'scale(0.5)';
                } else if (clWidth < 1600) {
                    this.scale = 'scale(0.6)';
                } else if (clWidth < 2000) {
                    this.scale = 'scale(0.8)';
                } else {
                    this.scale = '';
                }
            },
            // 监听路由
            monitorRouter() {
                let history = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY)) || [];
                if (this.$route.query.name === 'RoamingIndoor') {
                    this.state = 2;
                    this.camera.rotation.y = 3;
                    this.controls.enabled = false;
                    document.getElementById('container_3D').addEventListener('mousemove', this.setRotation);
                    this.camera.aspect = document.getElementById('container_3D').innerWidth / document.getElementById('container_3D').innerHeight;
                    document.getElementById('container_3D').addEventListener('mousedown', this.mouseClick);
                    this.playerCollider = new Capsule(new Three.Vector3(12, 0.35, -42), new Three.Vector3(12, 2, -42), 0.5);
                } else if (history.length > 0 && history[history.length - 1].name === 'InsideBuilding') {
                    console.log(22222);
                    this.state = 2;
                    this.camera.rotation.y = 3;
                    this.controls.enabled = false;
                    document.getElementById('container_3D').addEventListener('mousemove', this.setRotation);
                    this.camera.aspect = document.getElementById('container_3D').innerWidth / document.getElementById('container_3D').innerHeight;
                    document.getElementById('container_3D').addEventListener('mousedown', this.mouseClick);
                    if (window.localStorage.getItem('damen') == 'damen3') {
                        this.playerCollider = new Capsule(new Three.Vector3(40, 0.35, -42), new Three.Vector3(40, 2, -42), 0.5);
                    } else {
                        this.playerCollider = new Capsule(new Three.Vector3(70, 0.35, -75), new Three.Vector3(70, 2, -75), 0.5);
                        this.camera.rotation.y = 182;
                    }

                }
            },
            initEChart(elName, options) {
                const myChart = echarts.init(document.getElementById(elName));
                myChart.setOption(options);
                charts.push(myChart);
                return myChart;
            },
            /**
             * 更改视频播放状态
             * @param {Number} id 视频id
             * */
            async changeCameraVideoPlayStatus(id = 6) {
                const player = await this.queryCameraStreamUrl(id);
                if (!player) {
                    this.$vc.message('打开实时视频失败');
                    return;
                }
                player.play();
            },
            /**
             * 获取视频流地址
             * @param {Number} id 监控id
             * @param {String} videoEl 显示监控的标签id或者元素
             * */
            async queryCameraStreamUrl(id, videoEl) {
                const data = await this.$fly.get(openRealTimeStreamUrl, {id});
                let cameraStreamUrl = data.data;
                cameraStreamUrl = cameraStreamUrl.replace('https://dashboard.cosmos-property.com/', 'https://39.106.88.99/')
                console.log(cameraStreamUrl, 999999999)
                try {
                    console.log('开始调用初始化监控函数', id);
                    const player = await initStreamVideoPlayer((err) => {
                            console.log(err, '监控的错误信息')
                        }, videoEl ? videoEl : `camera_7`,
                        {
                            url: cameraStreamUrl
                        }
                    );
                    return player;
                } catch (err) {
                    console.log(err, '初始化监控错误信息');
                }
            },
            /**
             * 单个监控一场抓拍
             */
            async queryCameraCapture() {
                const res = await this.$fly.get(queryCameraCaptureAbnormalInfoUrl, {
                    id: 6,
                    pageSize: 7,
                    pageNo: 1,
                })
                if (res.code != 0) {
                    return
                }
                this.cameraCaptureList = res.data?.datas;
            },
            // 获取监控最新抓拍
            async queryCaptureNewFacePhoto() {
                const res = await this.$fly.get(queryCaptureNewFacePhotoUrl, {
                    id: 6,
                    pageSize: 4,
                    pageNo: 1,
                })
                if (res.code != 0) {
                    return;
                }
                this.captureNewFacePhotoList = res.data?.datas;
            },
            initBuildingInfo() {
                this.getUserCount();
                this.getLesseeCount();
                this.queryTenantRoomArea();
                this.getRentalRate();
            },
            // 查询楼内用户信息
            async getUserCount() {
                let res = await this.$fly.get(queryUserByBuildingUrl, {
                    regionCode: this.regionCode,
                    buildingCode: this.buildingCode,
                })
                if (res.code != 0) {
                    return;
                }
                this.userCount = res.data.userCount;
                this.genderRate = {
                    femaleRate: res.data.female,
                    manRate: res.data.male,
                }
                let maximumProportion = 0;
                console.log(res.data)
                this.ageInterList = res.data.ageInterval.map(item => {
                    let itemCopy = {...item};
                    item.countRate > maximumProportion ? maximumProportion = item.countRate : maximumProportion;
                    console.log(itemCopy)
                    return itemCopy;
                })
                console.log(this.ageInterList);
                this.ageMagnification = 100 / maximumProportion;
            },
            // 查询入驻企业
            async getLesseeCount() {
                let res = await this.$fly.get(queryLesseeByBuildingUrl, {
                    regionCode: this.regionCode,
                    buildingCode: this.buildingCode,
                })
                if (res.code != 0) {
                    return;
                }
                this.lesseeCount = res.data;
            },
            // 获取租赁面积
            queryTenantRoomArea() {
                this.$fly.get(queryBuildingOccRateUrl, {
                    regionCode: this.regionCode,
                    buildingCode: this.buildingCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        let data = res.data[0];
                        this.sumArea = data.buildingRoomSpaceSum;
                        this.regionRoomCount = data.buildingRoomCount;
                        // 租赁情况
                        this.initEChart('tenant_3D', {
                            color: ['#fff', '#FFD301'],
                            series: [
                                {
                                    name: '租赁情况',
                                    type: 'pie',
                                    radius: ['50%', '60%'],
                                    itemStyle: {
                                        borderWidth: 6,
                                        borderCap: 'butt',
                                        borderColor: 'rgba(0, 0, 0, 0)'
                                    },
                                    label: {
                                        formatter: '{b}{d}%',
                                        width: 80,
                                        height: 200,
                                        align: 'left',
                                        overflow: 'break',
                                        fontSize: 18,
                                        show: true,
                                        position: 'outside',
                                    },
                                    labelLine: {
                                        show: true,
                                        /*length: 30,
                                        length2: 20,*/
                                    },
                                    data: [
                                        // {value: data.buildingRoomUnOccRate, name: '空置率', label: {color: '#fff'}},
                                        // {value: data.buildingRoomOccRate, name: '出租率', label: {color: '#FFD301'}},

                                        // 展示用假数据
                                        {value: 76, name: '空置率', label: {color: '#fff'}},
                                        {value: 24, name: '出租率', label: {color: '#FFD301'}},
                                    ],
                                }
                            ]
                        })
                    })
            },
            // 获取楼栋出租率
            async getRentalRate() {
                let res = await this.$fly.post(stateRoomUrl, {
                    regionId: this.regionCode,
                    buildingId: this.buildingCode,
                    state: 1,
                })
                if (res.code != 0) {
                    return;
                }
                this.rentalRate = res.data.map(item => {
                    let itemCopy = {};
                    itemCopy.buildingName = item.floor;
                    itemCopy.buildingRoomCount = item.rooms.length;
                    let totalArea = 0;
                    let rentalArea = 0;
                    let unleasedArea = 0;
                    item.rooms.forEach(item => {
                        totalArea += item.space;
                        item.renten ? unleasedArea += item.space : rentalArea += item.space;
                    })
                    itemCopy.buildingRoomOccRate = (unleasedArea / totalArea).toFixed() * 100;
                    itemCopy.buildingRoomSpaceSum = totalArea;
                    return itemCopy;
                }).slice(0, 5);
            },
            // 获取楼栋id
            async getBuilding(modelName) {
                let res = await this.$fly.get(queryBuildingCodeByNameUrl, {
                    regionCode: this.regionCode,
                    modelName,
                })
                if (res.code != 0) {
                    return;
                }
                this.buildingCode = res.data;
                this.initBuildingInfo();
            },
        },
    }
</script>

<style lang="stylus">
    .isBian1
        width 350px
        height 100%
        background-color black
        position fixed
        top 0
        left 0

    .isBian2
        width 350px
        height 100%
        background-color black
        position fixed
        top 0
        right 0

    .isBian3
        width 100%
        height 70px
        background-color black
        position fixed
        top 0
        right 0

    .isBian4
        width 100%
        height 70px
        background-color black
        position fixed
        bottom 0
        right 0

    #container_3D
        width 100%
        height 100%
        position relative

        canvas
            width 100% !important
            height 100% !important

        #layer
            height 100%
            width 100%
            background-color rgba(0, 0, 0, 0.5)
            position fixed
            top 0
            left 0

            .progressStrip
                width 520px
                height 70px
                position absolute
                top 50%
                left 50%
                border-radius 16px
                transform translate(-50%, -50%)

                .progressImg
                    width 485px
                    height 100px
                    margin-left 17px

                    div
                        height 100px
                        overflow hidden

                        img
                            width 485px
                            height 100px

                .progressBar
                    width 520px
                    height 70px
                    background #999
                    border 5px solid #fff
                    border-radius 14px

                    .white
                        height 100%
                        background-color #fff
                        display inline-block
                        border-radius 6px

                .tips
                    color #fff
                    font-size 44px
                    text-align center

        .switch
            width 34px
            height 34px
            position absolute
            right 10px
            bottom 10px

            .panorama
                height 100%
                width 100%

            .roam
                height 100%
                width 100%

        .zoom
            width 30px
            height 30px
            position absolute
            right 10px
            top 0
            font-size 30px

    .dialog-content
        padding 30px 30px 0 30px

        .row
            width 100%
            margin-bottom 30px
            font-size 38px

            .title-info
                width 100%
                text-align center

    .dialog-container
        padding 20px 20px 0 20px

        .survey
            border-bottom 1px solid #fff
            display flex
            align-items center

            .enterprise
                width 180px
                text-align center

                .num
                    color #FFD301
                    font-size 28px
                    margin-bottom 0

                .text
                    color #fff
                    font-size 22px
                    margin-bottom 0

            .line
                width 1px
                height 62px
                background-color #fff

            .user
                @extends .enterprise

        .sex
            margin-top 20px

            .sex_title
                position relative
                margin-bottom 15px

                img
                    width 350px
                    height 40px

                .text
                    position absolute
                    font-size 24px
                    color #fff
                    top 0
                    margin-left 10px

            .man
                margin-bottom 10px
                display flex
                align-items center

                .list
                    .icon
                        margin-right 7px

                .proportion
                    text-align center

                    .percentage
                        font-size 14px
                        color #00E6FF
                        margin-bottom 10px

                    .text
                        font-size 12px
                        color #fff
                        margin-bottom 0

            .woman
                @extends .man
                .proportion
                    .percentage
                        color #FF5A5A

        .ageDistribution
            .sex_title
                position relative
                margin-bottom 15px

                img
                    width 350px
                    height 40px

                .text
                    position absolute
                    font-size 24px
                    color #fff
                    top 0
                    margin-left 10px

            .chart
                .chart-item
                    margin-bottom 15px

                    .chart-item-info
                        display flex
                        align-items center
                        margin-bottom 4px

                        span
                            margin-right 20px
                            color #fff
                            font-size 14px

                        .square
                            display inline-block
                            width 10px
                            height 10px
                            margin-right 15px
                            background-color #FFD301

                    .line
                        width 100%
                        height 6px

                        .line-bg
                            width 100%
                            height 100%
                            display flex
                            align-items center

                            .line-color
                                height 100%
                                background-color #FFD301
                                border-radius 3px
                                margin-right 10px
                                transition all 1s linear

                            .num
                                color #FFD301
                                font-size 14px

        .rentalRate
            border-bottom 1px solid #fff
            margin-bottom 20px

        .floorOccupancyRate
            .title
                position relative
                margin-bottom 15px

                img
                    width 350px
                    height 40px

                .text
                    position absolute
                    font-size 24px
                    color #fff
                    top 0
                    margin-left 10px

            .floorList
                .chart
                    .chart-item
                        margin-bottom 15px

                        .chart-item-info
                            display flex
                            align-items center
                            margin-bottom 4px

                            span
                                margin-right 20px
                                color #fff
                                font-size 14px

                            .square
                                display inline-block
                                width 10px
                                height 10px
                                margin-right 15px
                                background-color #FFD301

                        .line
                            width 100%
                            height 6px
                            display flex
                            align-items center

                            .line-bg
                                width 85%
                                height 100%
                                background-color #fff
                                border-radius 3px
                                margin-right 10px
                                display inline-block

                                .line-color
                                    height 100%
                                    background-color #FFD301
                                    border-radius 3px
                                    margin-right 10px
                                    transition all 1s linear

                            .num
                                color #FFD301
                                font-size 14px

    .abnormal
        position absolute
        height 40px
        padding 0 20px
        background-color #FFE6E6
        border-radius 30px
        color #FF5A5A
        line-height 40px
        font-size 28px
        text-align center

    #tenant_3D
        height 200px
        width 400px

    .lease-situation-chart
        display flex
        align-items center

        .info
            width 100px

            .room
                text-align center

                .num
                    color #FFD301
                    font-size 14px
                    margin-bottom 0

                .text
                    color #FFF
                    font-size 14px

            .area
                @extends .room

    .row_img
        position absolute
        display flex
        justify-content flex-start
        left 20px
        bottom 20px
        width 1100px
        height 160px
        padding 20px
        background-color rgba(255, 255, 255, 0.3)
        border-radius 5px

        .row_img_item
            text-align center

            &:not(:last-of-type)
                margin-right 40px

            img
                width 80px
                height 80px
                margin-bottom 10px

            .row_img_item_type
                width 120px
                height 40px
                border-radius 20px
                font-size 24px
                line-height 40px
                background-color #FFE6E6
                color #FF5A5A

            .green
                background-color #D8FFF8
                color #00B694

    .col_img
        position absolute
        top 20px
        right 20px
        width 110px
        height 420px
        padding 15px
        border-radius 5px
        background-color rgba(255, 255, 255, 0.3)

        .col_img_item
            width 80px
            height 80px

            &:not(:last-of-type)
                margin-bottom 24px
</style>
